import axios from 'axios';
import Vue from 'vue'

function detetoken () {
  axios.get(
    // '/token/check?', // 请求的后台接口
    {
      params: {

      } // 传给后台的参数
    }).then(({
    data
  }) => {
    console.log('验证token=》', data)
  })
}

// 将方法暴露出来
export default {
  detetoken

}
