import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts';
import geoJson from '@/utils/china2.json'
echarts.registerMap('china', geoJson);

// 引入全局css
import './assets/total.css'
// 引入全局rem.js
import './assets/rem'
// 引入 Element 的全部组件
import ElementUI from 'element-ui'
// 引入 Element 样式
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN'
Vue.use(ElementUI, { locale })
//数字递增
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

// echarts
Vue.prototype.$echarts = echarts


// axios
// 在main.js中 导入 axios，在组件中使用时不需要再进行导入
import axios from 'axios'
// 全局配置 axios 的请求根路径
axios.defaults.baseURL = 'https://www.adsipr.com/api'
// 把axios挂载到Vue的原型上
Vue.prototype.$http = axios
// 在每个vue组件中要发起请求，直接调用this.$http.xxx
// 缺点：无法实现 api 的复用s

//将公共方法挂载this
import fun from './common/common.js'
Vue.prototype.$fun = fun;

Vue.config.productionTip = false


new Vue({
  router,
  store,
  ElementUI,
  render: h => h(App)
}).$mount('#app')


// 加入百度统计
router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  next()
})
