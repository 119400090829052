import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },

  {
    path: '/businessView',
    name: 'businessView',
    component: () => import('../views/businessView.vue'),
  },
  {
    path: '/businessDetail',
    name: 'businessDetail',
    component: () => import('../views/businessDetail.vue')
  },
  {
    path: '/solutionView',
    name: 'solutionView',
    component: () => import('../views/solutionView.vue')
  },
  {
    path: '/solutionDetail',
    name: 'solutionDetail',
    component: () => import('../views/solutionDetail.vue')
  },
  {
    path: '/solutionCase',
    name: 'solutionCase',
    component: () => import('../views/solutionCase.vue')
  },
  {
    path: '/newView',
    name: 'newView',
    component: () => import('../views/newView.vue')
  },
  {
    path: '/newDetail',
    name: 'newDetail',
    component: () => import('../views/newDetail.vue')
  }, 
  {
    path: '/newCase',
    name: 'newCase',
    component: () => import('../views/newCase.vue')
  }, 
  {
    path: '/contactView',
    name: 'contactView',
    component: () => import('../views/contactView.vue')
  }, 
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue')
  }, 
  {
    path: '/mobile/mobilehomeview',
    name: 'mobilehomeview',
    component: () => import('../views/mobile/mobilehomeview.vue')
  },
  {
    path: '/mobile/aboutView',
    name: 'aboutView',
    component: () => import('../views/mobile/aboutView.vue')
  },
  {
    path: '/mobile/productsView',
    name: 'productsView',
    component: () => import('../views/mobile/productsView.vue')
  },
  {
    path: '/mobile/productsDetail',
    name: 'productsDetail',
    component: () => import('../views/mobile/productsDetail.vue')
  },
  {
    path: '/mobile/solutionView',
    name: 'solutionView',
    component: () => import('../views/mobile/solutionView.vue')
  },
  {
    path: '/mobile/solutionDetail',
    name: 'solutionDetail',
    component: () => import('../views/mobile/solutionDetail.vue')
  },
  {
    path: '/mobile/newsView',
    name: 'newsView',
    component: () => import('../views/mobile/newsView.vue')
  },
  {
    path: '/mobile/newsDetail',
    name: 'newsDetail',
    component: () => import('../views/mobile/newsDetail.vue')
  },
  {
    path: '/mobile/MnewCase',
    name: 'newCase',
    component: () => import('../views/mobile/MnewCase.vue')
  }, 
  {
    path: '/mobile/contactView',
    name: 'contactView',
    component: () => import('../views/mobile/contactView.vue')
  }, {
    path: '/download',
    name: 'download',
    component: () => import('../views/download.vue')
  }, {
    path: '/mobile/mobiledownload',
    name: 'mobiledownload',
    component: () => import('../views/mobile/mobiledownload.vue')
  },
  {
    path: '/mobile/MsolutionCase',
    name: 'MsolutionCase',
    component: () => import('../views/mobile/MsolutionCase.vue')
  }
]

const router = new VueRouter({
  routes
})

// router文件夹下等index.js文件中写入
//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

export default router
