<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    
  },

  methods: {
    
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

::v-deep .el-tabs__item.is-active {
  color: #fff;
  background: #eca831;
}
::v-deep .el-tabs__item {
  border-radius: 100px;
  border: 1px solid #e1e4e6;
  margin-left: 0.2rem;
  height: 0.48rem;
  line-height: 0.48rem;
  font-size: 0.16rem;
}

::v-deep .el-tabs--card > .el-tabs__header {
  border: none;
}
</style>
